import React, { MouseEvent } from "react";

import AutocompleteConfig from "src/components/autocomplete/autocomplete_config";
import { BaseItem } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import {
  restaurantPath,
  searchRestaurantsPath,
  storeAttachmentImageryPath,
  storefrontsPastStoresPath
} from "src/routes";
import { SearchClient } from "algoliasearch/lite";
import { ReactComponent as ImageSquareFallback } from "../../../images/image-square-fallback.svg";
import searchInsights from "search-insights";

export interface StoreResult extends BaseItem {
  objectID: string | number;
  name: string;
  slug: string;
  __autocomplete_queryID?: string;
  image_thumbnail_id?: number;
}

export default class StoresAutocompleteConfig extends AutocompleteConfig<StoreResult> {
  storeIds: Array<number>;
  excludeAgeRestrictedStores: boolean;
  imageBaseUrl: string;

  constructor(indexName: string, imageBaseUrl: string, storeIds?: Array<number>, excludeAgeRestrictedStores?: boolean) {
    super(indexName);

    this.storeIds = storeIds || [];
    this.excludeAgeRestrictedStores = excludeAgeRestrictedStores || false;
    this.imageBaseUrl = imageBaseUrl;
  }

  emptyQueryCollectionId = "past_stores";
  emptyQueryCollectionTitle = "Order again";
  queryCollectionId = "stores"

  filterString(): string {
    const storesFilter = `(${this.storeIds.map((store_id) => `objectID:${store_id}`).join(" OR ")})`;
    const ageRestrictedFilter = this.excludeAgeRestrictedStores ? " AND requires_age_verification:false" : ""

    return storesFilter + ageRestrictedFilter;
  }

  emptyQueryResults() {
    return {
      sourceId: this.emptyQueryCollectionId,
      async getItems() {
        const resp = await fetch(storefrontsPastStoresPath(), { headers: { "Content-Type": "application/json" } });

        return (await resp.json() as { results: StoreResult[] } )["results"];
      },
      templates: {
        item() {
          return '<div></div>'
        }
      }
    }
  }

  queryResults(searchClient: SearchClient) {
    return {
      sourceId: this.queryCollectionId,
      getItems: ({ query }: { query: string }) => {
        if (query.length == 0) return [];
        return getAlgoliaResults<StoreResult>({
          searchClient,
          queries: [
            {
              indexName: this.indexName,
              query,
              params: {
                hitsPerPage: 5,
                filters: this.filterString(),
              },
            },
          ],
        });
      },
      templates: {
        item() {
          return '<div></div>'
        }
      }
    };
  }

  searchResultsPath(query: string) {
    return searchRestaurantsPath({ "search_bar": query });
  }

  topSearchResult(): React.JSX.Element[] {
    return [];
  }

  itemComponent(item: StoreResult, index: number): React.JSX.Element {
    const imageUrl = item.image_thumbnail_id ? `${this.imageBaseUrl}/${storeAttachmentImageryPath(item.image_thumbnail_id)}` : undefined

    const onClick = (_event: MouseEvent) => {
      if (item.__autocomplete_queryID) {
        document.cookie = `store_search_query_id=${item.__autocomplete_queryID};Max-Age=3600` // Algolia ignores events that are sent more than an hour after the query time

        searchInsights("clickedObjectIDsAfterSearch", {
          index: this.indexName,
          eventName: "Store clicked",
          queryID: item.__autocomplete_queryID,
          objectIDs: [item.objectID.toString()],
          positions: [index + 1], // Positions start from 1
        })
      }
    }

    return <a key={item.objectID} href={restaurantPath(item.slug)} onClick={onClick} className="list-item">
      {
        imageUrl ? <div
          className="thumbnail-image is-circle flex-shrink-0"
          data-lazy-images-target="image"
          data-lazy-images-imagery-path-value={imageUrl}
        /> : <ImageSquareFallback className="thumbnail-image is-circle icon streamline-icon is-medium"/>
      }
      <span className="label-text description">{item.name}</span>
    </a>
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input"
  ];

  declare inputTarget: HTMLInputElement;

  clearInput(): void {
    this.inputTarget.value = "";
    this.inputTarget.focus();
  }
}

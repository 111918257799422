import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare expandAnimation: Animation;
  declare collapseAnimation: Animation;

  connect() {
    // Get all the <a> and <details> elements
    const AnchorElements = this.element.querySelectorAll("a");
    const DetailsElements = this.element.querySelectorAll("details");

    AnchorElements.forEach(anchorElement => {
      anchorElement.addEventListener('click', (e) => {
        // Prevent default browser behaviour from firing
        e.preventDefault();

        // Remove the is-active class from the currently selected link
        AnchorElements.forEach(anchorElement => {
          if (anchorElement.classList.contains('is-active')) anchorElement.classList.remove('is-active');
        });

        // Set is-active on the newly selected link
        anchorElement.classList.add('is-active');

        const TargetAnchorElement = e.target as HTMLAnchorElement;

        DetailsElements.forEach(detailsElement => {
          const summaryElement = detailsElement.querySelector('summary') as HTMLElement;

          // If it isn't already open, expand the <details> element for the selected link.
          // Otherwise, close all other <details> elements except for the one just selected.
          if (!detailsElement.open && detailsElement.contains(TargetAnchorElement)) {
            this.expand(detailsElement, summaryElement);
            return;
          } else if (!detailsElement.contains(TargetAnchorElement)) {
            this.collapse(detailsElement, summaryElement);
          }
        });
      });
    });

    DetailsElements.forEach(detailsElement => {
      const summaryElement = detailsElement.querySelector('summary') as HTMLElement;

      summaryElement.addEventListener('click', (e) => {
        // Return early if the element clicked isn't the summary element
        if (e.currentTarget != e.target) return;

        // Prevent default browser behaviour from firing
        e.preventDefault();

        // Toggle expand or collapse the relevant <details> element
        const parentDetailsElement = summaryElement.parentNode as HTMLDetailsElement;
        parentDetailsElement.open ? this.collapse(parentDetailsElement, summaryElement) : this.expand(parentDetailsElement, summaryElement);
      });
    });
  }

  expand(detailsElement: HTMLDetailsElement, summaryElement: HTMLElement) {
    // Apply a fixed height inline style to the <details> element so that it doesn't jump
    // when forced open in order to be able to calculate the open and closed dimensions.
    detailsElement.style.height = `${detailsElement.offsetHeight}px`;
    detailsElement.open = true;

    // Wait until the next frame before starting to expand
    window.requestAnimationFrame(() => {
      const detailsContent = detailsElement.querySelector('ul') as HTMLUListElement;

      // Get the current startHeight of the <details> element, then calculate the endHeight
      // as if it were expanded.
      const startHeight = `${detailsElement.offsetHeight}px`;
      const endHeight = `${summaryElement.offsetHeight + detailsContent.offsetHeight}px`;

      // Trigger the animation
      const expandAnimation = detailsElement.animate({
        height: [startHeight, endHeight]
      }, {
        duration: 350,
        easing: 'ease'
      });

      // Remove the fixed height inline style when the animation is complete
      expandAnimation.onfinish = () => {
        detailsElement.style.height = '';
      };
    });
  }

  collapse(detailsElement: HTMLDetailsElement, summaryElement: HTMLElement) {
    // Get the current startHeight of the <details> element, then calculate the endHeight
    // as if it were collapsed.
    const startHeight = `${detailsElement.offsetHeight}px`;
    const endHeight = `${summaryElement.offsetHeight}px`;

    // Trigger the animation
    const collapseAnimation = detailsElement.animate({
      height: [startHeight, endHeight]
    }, {
      duration: 350,
      easing: 'ease'
    });

    // Close the <details> element when the animation is complete.
    collapseAnimation.onfinish = () => {
      detailsElement.open = false;
    };
  }
}
